import { render, staticRenderFns } from "./SchoolSlide.vue?vue&type=template&id=3faf282c&scoped=true&"
import script from "./SchoolSlide.vue?vue&type=script&lang=ts&"
export * from "./SchoolSlide.vue?vue&type=script&lang=ts&"
import style0 from "./SchoolSlide.vue?vue&type=style&index=0&id=3faf282c&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3faf282c",
  null
  
)

export default component.exports