





































































import {
    Vue, Component, Prop,
} from 'vue-property-decorator';

@Component
export default class SVGPortal extends Vue {
    @Prop({ default: 1 }) readonly openPercentage: number
    @Prop({ default: false }) readonly blue: boolean

    id:string;

    constructor() {
        super();
        this.id = Math.random().toString(36).substr(2, 11);
    }

    get portalTransform():string {
        return `scale(${this.openPercentage})`;
    }
    get portalStyle():Record<'opacity', number> {
        return { opacity: this.openPercentage };
    }
    get portalColorModifier():'blue' | 'orange' {
        return this.blue ? 'blue' : 'orange';
    }
    get portalFilterOuterID():string {
        return `filterOuter_${this.portalColorModifier}`;
    }
    get portalFilterInnerID():string {
        return `filterInner_${this.portalColorModifier}`;
    }
    get portalMaskPathID():string {
        return `portalMaskPath_${this.portalColorModifier}`;
    }
    get portalMaskID():string {
        return `portalMask_${this.portalColorModifier}`;
    }
    get portalMaskTransformTop():number {
        return this.blue ? 0 : 35;
    }
}
