




































import { Component } from 'vue-property-decorator';
import GraphicMixin from '@/mixins/GraphicMixin';
import AnimationPath from '@/components/Atoms/AnimationPath.vue';
import SVGSmoothPath from '@/classes/SVGSmoothPath';
import Point from '@/classes/Point';
import Path from '@/classes/Path';
import minMax from '@/helpers/minMax';

@Component({
    components: {
        AnimationPath,
    },
})
export default class DiscoverFrontendGraphic extends GraphicMixin {
    constructor() {
        super();
        this.graphicLayout = {
            text: {
                desktop: new Point({ x: 24, y: 32 }),
                mobile: new Point({ x: 4, y: 40 }),
            },
            textEndPoint: new Point({ x: this.end.x, y: this.end.y }),
        };
        this.timeline = [
            { key: 'timeline', start: 0, end: 1 },
            { key: 'strokeWidening', start: 0.6, end: 0.8 },
        ];
    }

    get relativeLineWidth():number {
        return 16 / this.windowWidth * 100;
    }
    get strokeWidth():number {
        const baseStrokeWidth = 4;
        return baseStrokeWidth + (16 * this.as.strokeWidening);
    }
    get timelinePath():string {
        let path = new Path({
            points: [
                new Point({ x: this.start.x, y: -1 }),
                new Point({ x: this.start.x, y: 0 }),
                new Point({ x: 50, y: 20 }),
                new Point({ x: this.end.x, y: this.end.y - 1 }),
                new Point({ x: this.end.x, y: this.end.y }),
            ],
        });
        if (this.windowWidth < 969) {
            path = new Path({
                points: [
                    new Point({ x: this.start.x, y: -1 }),
                    new Point({ x: this.start.x, y: 0 }),
                    new Point({ x: 50, y: 20 }),
                    new Point({ x: 50, y: 20 }),
                    new Point({ x: this.end.x, y: this.end.y - 1 }),
                    new Point({ x: this.end.x, y: this.end.y }),
                ],
            });
        }
        const { windowWidth, windowHeight } = this;
        return new SVGSmoothPath({ path, windowWidth, windowHeight }).SVGStringPath;
    }
    get titleElementWidth():number {
        const maxWidth = 700;
        const padding = 16;
        const width = this.coords.textEndPoint.x - this.coords.text.x - padding;
        return minMax({ number: width, min: 0, max: maxWidth });
    }
}
