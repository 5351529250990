



























































import { Component } from 'vue-property-decorator';
import AnimationPath from '@/components/Atoms/AnimationPath.vue';
import Point from '@/classes/Point';
import Path from '@/classes/Path';
import SVGSmoothPath from '@/classes/SVGSmoothPath';
import GraphicMixin from '@/mixins/GraphicMixin';

@Component({
    components: {
        AnimationPath,
    },
})
export default class SchoolDoodle extends GraphicMixin {
    constructor() {
        super();
        this.graphicLayout = {
            copybook: {
                mobile: new Point({ x: 10, y: 60 }),
                desktop: new Point({ x: 25, y: 60 }),
            },
            text: {
                mobile: new Point({ x: 10, y: 60 }),
                desktop: new Point({ x: 25, y: 60 }),
            },
        };
        this.timeline = [
            { key: 'text', start: 0.5, end: 0.9 },
            { key: 'redCopybookLine', start: 0, end: 1 },
            { key: 'noteLine1', start: 0.42, end: 0.88 },
            { key: 'noteLine2', start: 0.42, end: 0.86 },
            { key: 'noteLine3', start: 0.42, end: 0.84 },
            { key: 'noteLine4', start: 0.42, end: 0.82 },
            { key: 'noteLine5', start: 0.42, end: 0.8 },
            { key: 'noteLine6', start: 0.42, end: 0.82 },
            { key: 'noteLine7', start: 0.42, end: 0.84 },
            { key: 'noteLine8', start: 0.42, end: 0.86 },
            { key: 'noteLine9', start: 0.42, end: 0.90 },
            { key: 'houseFrame', start: 0.5, end: 0.75 },
            { key: 'houseDoor', start: 0.51, end: 0.75 },
            { key: 'houseWindow', start: 0.53, end: 0.75 },
            { key: 'tree1', start: 0.588, end: 0.8 },
            { key: 'tree2', start: 0.659, end: 0.85 },
            { key: 'timeline', start: 0, end: 1 },
        ];
    }

    get redLineX():number {
        return (this.windowWidth > 484) ? 77 : 14;
    }
    get timelinePath():string {
        const path = new Path({
            points: [
                this.getPercentageFromPixelPoint({ x: this.start.x, y: -1 }),
                this.getPercentageFromPixelPoint({ x: this.start.x, y: 0 }),
                new Point({ x: 10, y: 40 }),
                new Point({ x: 10, y: 41 }),
                new Point({ x: 30, y: 60 }),
                new Point({ x: 70, y: 60 }),
                new Point({ x: this.end.x, y: 100 }),
                new Point({ x: this.end.x, y: 101 }),
            ],
        });
        const SVGPath = new SVGSmoothPath({
            path,
            windowWidth: this.windowWidth,
            windowHeight: this.windowHeight,
        }).SVGStringPath;
        return SVGPath;
    }
    get textWidth():number {
        const copybookStart = Math.round(
            this.getPercentageFromPixelPoint(new Point({ x: this.coords.copybook.x, y: 0 })).x,
        );
        const start = this.getPixelFromPercentagePoint(
            new Point({ x: copybookStart, y: 40 }),
        ).x;
        let end = (start + 617);
        if (this.windowWidth < 1030) {
            const timelinePassThrough = this.getPixelFromPercentagePoint(new Point({
                x: this.end.x - 10,
                y: 0,
            })).x;
            end = timelinePassThrough;
        }
        return end - start;
    }
}
