









































import { Component, Watch } from 'vue-property-decorator';
import {
    State,
    Mutation,
} from 'vuex-class';

import SlideMixin from '@/mixins/SlideMixin';
import Point from '@/classes/Point';
import Slide from '@/classes/Slide';
import Line from '@/classes/Line';
import easingFunctions from '@/helpers/easingFunctions';
import AudioVisualizer from '@/helpers/AudioVisualizer';

@Component({
    components: {
        AudioAnimation: () => import('@/components/Molecules/AudioAnimation.vue'),
        AudioVizualization: () => import('@/components/Illustrations/AudioVisualization.vue'),
        AudioControls: () => import('@/components/Molecules/AudioControls.vue'),
    },
})
export default class MusicVisualisationSlide extends SlideMixin {
    @State('audioPermission') audioPermission: boolean;
    @Mutation('setAudioPermission') readonly setAudioPermission: CallableFunction;
    end:Point;
    playing:boolean;
    showAudioControls: boolean;
    audioVisualizer:AudioVisualizer;
    audioDataArray:Uint8Array = new Uint8Array();

    constructor() {
        super();
        this.audioVisualizer = new AudioVisualizer(
            'assets/sounds/android52 - ANDROID52 COLLECT - 09 The Story of the Girl That Fell from the Sky.mp3',
            this.setAudioData,
            50,
            () => {
                this.audioOnPlayHandler();
            },
        );
        this.playing = false;
        this.showAudioControls = false;
        this.end = new Point({ x: 8, y: 100 });
    }

    public get animationPercentage():number {
        return easingFunctions.linear(this.entered);
    }
    private get previousSlide():Slide|null {
        return this.slides[this.index - 1];
    }
    private get lineStartPosition():Point {
        if (!this.previousSlide) return new Point({ x: 0, y: 0 });
        return this.previousSlide.line.end;
    }
    private get timelinePosition():Line {
        return new Line({
            start: new Point({ x: this.lineStartPosition.x, y: 0 }),
            end: this.end,
        });
    }
    private get startTresholdPassed():boolean {
        return this.entered > -0.20;
    }
    public setAudioData(dataArray:Uint8Array):void {
        this.audioDataArray = dataArray.slice();
    }
    public playButtonClickHandler():void {
        this.setAudioPermission(true);
        this.startPlaying();
    }
    @Watch('startTresholdPassed')
    public startPlaying():void {
        if (!this.startTresholdPassed || !this.audioPermission) return;
        this.audioVisualizer.play();
    }
    private audioOnPlayHandler() {
        this.$gtag.event('audio_play', {
            event_category: 'Audio',
            event_label: 'Audio Playing',
            value: 0,
        });
        this.showAudioControls = true;
    }

    mounted():void {
        this.registerSlide(
            new Slide({
                index: this.index,
                line: new Line({ ...this.timelinePosition }),
            }),
        );
    }
}
