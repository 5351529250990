

































































import { Component } from 'vue-property-decorator';
import GraphicMixin from '@/mixins/GraphicMixin';
import AnimationPath from '@/components/Atoms/AnimationPath.vue';
import SVGSmoothPath from '@/classes/SVGSmoothPath';
import Point from '@/classes/Point';
import Path from '@/classes/Path';
import SVGPortal from '@/components/Atoms/SVGPortal.vue';

@Component({
    components: {
        AnimationPath,
        SVGPortal,
    },
})
export default class DDUnleash extends GraphicMixin {
    constructor() {
        super();
        this.graphicLayout = {
            introTextCoords: new Point({ x: 20, y: 33 }),
            portalInCoords: new Point({ x: 20, y: 0 }),
            dsotmCoords: new Point({ x: 46, y: 55 }),
        };
        this.timeline = [
            { key: 'timeline', start: 0.16, end: 0.7 },
            { key: 'timelinePrisma', start: 0.7, end: 1 },
            { key: 'portalLine', start: 0.10, end: 0.2 },
            { key: 'textLine1', start: 0.35, end: 0.55 },
            { key: 'textLine2', start: 0.65, end: 0.8 },
            { key: 'textLine3', start: 0.7, end: 0.8 },
        ];
    }

    get relativeLineWidth():number {
        return 16 / this.windowWidth * 100;
    }
    get prismaPath():string {
        const path = new Path({
            points: [
                new Point({ x: 46, y: 55 }),
                new Point({ x: 66, y: 55 }),
                new Point({ x: 76, y: 90 }),
                this.end,
            ],
        });
        const { windowWidth, windowHeight } = this;
        return new SVGSmoothPath({ path, windowWidth, windowHeight }).SVGStringPath;
    }

    getTimeLinePath(index:number):string {
        const relativeLineHeight = 16 / this.windowHeight * 100;
        const startPointX = 20 - (index * this.relativeLineWidth);
        const midPointX = 20 - (index * this.relativeLineWidth);
        const midPointY = 40 - (index * relativeLineHeight * 2);
        const endPointY = 55 + (index * relativeLineHeight);
        const startY = this.getPercentageFromPixelPoint(new Point({ x: 0, y: 80 })).y;

        const path = new Path({
            points: [
                new Point({ x: startPointX, y: startY }),
                new Point({ x: startPointX, y: startY + 1 }),
                new Point({ x: midPointX, y: midPointY }),
                new Point({ x: midPointX, y: midPointY + 1 }),
                new Point({ x: 45, y: endPointY }),
                new Point({ x: 45 + 1, y: endPointY }),
            ],
        });
        const { windowWidth, windowHeight } = this;
        return new SVGSmoothPath({ path, windowWidth, windowHeight }).SVGStringPath;
    }
    getportalLinePath(index:number):string {
        const x = 0 + (index * this.relativeLineWidth);
        const endY = this.getPercentageFromPixelPoint(new Point({ x: 0, y: 80 })).y;
        const path = new Path({
            points: [
                new Point({ x, y: 0 }),
                new Point({ x, y: endY }),
            ],
        });
        const { windowWidth, windowHeight } = this;
        return new SVGSmoothPath({ path, windowWidth, windowHeight }).SVGStringPath;
    }
    getTextIntroStyle(asID:string):string {
        return `opacity: ${this.as[asID]}`;
    }
}
