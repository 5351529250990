











import {
    Component, Prop, Watch,
} from 'vue-property-decorator';
import GraphicMixin from '@/mixins/GraphicMixin';

@Component
export default class RainbowBackground extends GraphicMixin {
    @Prop({ required: true }) readonly effectType: string

    constructor() {
        super();
        this.timeline = [
            { key: 'rainbow_block_0', start: 0, end: 1 },
            { key: 'rainbow_block_1', start: 0.03, end: 1 },
            { key: 'rainbow_block_2', start: 0.06, end: 1 },
            { key: 'rainbow_block_3', start: 0.09, end: 1 },
            { key: 'rainbow_block_4', start: 0.12, end: 1 },
            { key: 'rainbow_block_5', start: 0.15, end: 1 },
        ];
    }

    @Watch('windowSizeSum')
    private getRainbowStyle(index:number):Record<string, string> {
        const animationPercentage = this.as[`rainbow_block_${index}`];
        const borderRadius = 32 - (32 * this.as[`rainbow_block_${index}`]);
        let margin = 16 - (16 * this.as[`rainbow_block_${index}`]);

        if (this.effectType === 'stretch') {
            margin = 0;
        }

        return {
            height: `${this.windowHeight * animationPercentage}px`,
            marginLeft: `${margin}px`,
            marginRight: `${margin}px`,
            borderBottomLeftRadius: `${borderRadius}px`,
            borderBottomRightRadius: `${borderRadius}px`,
        };
    }
}
