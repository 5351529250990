











import { Component } from 'vue-property-decorator';
import SlideMixin from '@/mixins/SlideMixin';
import Point from '@/classes/Point';
import Slide from '@/classes/Slide';
import Line from '@/classes/Line';
import animationStep from '@/helpers/animationStep';
import Color from 'color';

@Component({
    components: {
        HereNowGraphic: () => import('@/components/Illustrations/HereNowGraphic.vue'),
    },
})

export default class HereNowSlide extends SlideMixin {
    end:Point;

    constructor() {
        super();
        this.end = new Point({ x: 50, y: 30 });
    }

    get previousSlide():Slide|null {
        return this.slides[this.index - 1];
    }
    get lineStartPosition():Point {
        if (!this.previousSlide) return new Point({ x: 0, y: 0 });
        return this.previousSlide.line.end;
    }
    get timelinePosition(): Line {
        return new Line({
            start: this.lineStartPosition,
            end: this.end,
        });
    }
    get backgroundColor(): string {
        const percentage = animationStep({
            parentPercentage: this.exited,
            start: 0,
            end: 1,
        });
        const startColor = Color(this.colors.$marine_dark);
        const endColor = Color(this.colors.$marine_light);
        const color = startColor.mix(endColor, percentage);
        return color;
    }


    mounted():void {
        this.registerSlide(
            new Slide({
                index: this.index,
                line: new Line({ ...this.timelinePosition }),
            }),
        );
    }
}
