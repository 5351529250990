









































import {
    Prop, Component, Vue, Watch,
} from 'vue-property-decorator';
import AudioVisualizer from '@/helpers/AudioVisualizer';

@Component
export default class AudioControls extends Vue {
    @Prop({ required: true }) readonly audioVisualizer: AudioVisualizer

    volume:number;

    constructor() {
        super();
        this.volume = this.audioVisualizer.volume;
    }

    @Watch('volume')
    private setVolume():void {
        this.audioVisualizer.setVolume(this.volume);
    }
}
