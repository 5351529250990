

















































import { Component } from 'vue-property-decorator';
import GraphicMixin from '@/mixins/GraphicMixin';
import AnimationPath from '@/components/Atoms/AnimationPath.vue';
import SVGSmoothPath from '@/classes/SVGSmoothPath';
import Point from '@/classes/Point';
import Path from '@/classes/Path';
import SVGPortal from '@/components/Atoms/SVGPortal.vue';

@Component({
    components: {
        AnimationPath,
        SVGPortal,
    },
})
export default class DDIntro extends GraphicMixin {
    graphicLayout = {
        introTextCoords: new Point({ x: 27, y: 52 }),
        portalInCoords: new Point({ x: 20, y: 77 }),
    }
    timeline = [
        { key: 'timeline', start: 0, end: 0.8 },
        { key: 'textLine1', start: 0.6, end: 0.8 },
        { key: 'textLine2', start: 0.65, end: 0.8 },
        { key: 'textLine3', start: 0.7, end: 0.8 },
        { key: 'portalLine', start: 0.8, end: 1 },
        { key: 'portalIntro', start: 0.72, end: 0.83 },
    ]

    get relativeLineWidth():number {
        return 16 / this.windowWidth * 100;
    }

    getTimeLinePath(index:number):string {
        const startPointX = this.start.x + (index * this.relativeLineWidth);
        const endPointX = 20 - (index * this.relativeLineWidth);

        const path = new Path({
            points: [
                new Point({ x: startPointX, y: -1 }),
                new Point({ x: startPointX, y: 0 }),
                new Point({ x: endPointX, y: 56 }),
                new Point({ x: endPointX, y: 57 }),
                new Point({ x: endPointX, y: 77 }),
            ],
        });
        const { windowWidth, windowHeight } = this;
        return new SVGSmoothPath({ path, windowWidth, windowHeight }).SVGStringPath;
    }
    getportalLinePath(index:number):string {
        const X = 0 + (index * this.relativeLineWidth);
        const path = new Path({
            points: [
                new Point({ x: X, y: 0 }),
                new Point({ x: X, y: 27 }),
            ],
        });
        const { windowWidth, windowHeight } = this;
        return new SVGSmoothPath({ path, windowWidth, windowHeight }).SVGStringPath;
    }
    getTextIntroStyle(asID:string):string {
        return `opacity: ${this.as[asID]}`;
    }
}
