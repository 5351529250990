import { render, staticRenderFns } from "./DDUnleashSlide.vue?vue&type=template&id=13547b05&scoped=true&"
import script from "./DDUnleashSlide.vue?vue&type=script&lang=ts&"
export * from "./DDUnleashSlide.vue?vue&type=script&lang=ts&"
import style0 from "./DDUnleashSlide.vue?vue&type=style&index=0&id=13547b05&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13547b05",
  null
  
)

export default component.exports