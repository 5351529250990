






import {
    Vue, Component, Prop,
} from 'vue-property-decorator';

@Component
export default class Slide extends Vue {
    @Prop({ default: '#F9F9F9' }) readonly background: string

    get slideStyle():Record<string, string> {
        return {
            'background-color': this.background,
        };
    }
}
