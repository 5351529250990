

















































import { Component } from 'vue-property-decorator';
import formatDistance from 'date-fns/formatDistance';
import GraphicMixin from '@/mixins/GraphicMixin';
import AnimationPath from '@/components/Atoms/AnimationPath.vue';
import SVGSmoothPath from '@/classes/SVGSmoothPath';
import Point from '@/classes/Point';
import Path from '@/classes/Path';

@Component({
    components: {
        AnimationPath,
    },
})
export default class AdyenGraphic extends GraphicMixin {
    titleElementWidth: number;
    mainText: string;

    constructor() {
        super();
        this.graphicLayout = {
            text: {
                desktop: new Point({ x: 46, y: 30 }),
                mobile: new Point({ x: 32, y: 30 }),
            },
        };
        this.timeline = [
            { key: 'timeline', start: 0, end: 1 },
            { key: 'greenPath1', start: 0, end: 1 },
            { key: 'textOpacity', start: 0.2, end: 0.4 },
        ];
        this.titleElementWidth = 600;
        const timeSinceAdyen = formatDistance(new Date(2021, 6, 1), Date.now());
        this.mainText = `Where I started ${timeSinceAdyen} ago and am having an awesome time, while also learning a lot.`;
    }

    get relativeLineWidth():number {
        return 16 / this.windowWidth * 100;
    }
    get timelinePath():string {
        const path = this.getMainPath();
        const { windowWidth, windowHeight } = this;
        return new SVGSmoothPath({ path, windowWidth, windowHeight }).SVGStringPath;
    }
    get greenPaths():Array<string> {
        const paths:Array<string> = [];
        for (let index = 0; index < 6; index += 1) {
            const { windowWidth, windowHeight } = this;
            const pathRight = this.getMainPath(index + 1.75);
            const pathLeft = this.getMainPath(-(index + 1.75));
            paths.push(new SVGSmoothPath({ path: pathRight, windowWidth, windowHeight }).SVGStringPath);
            paths.push(new SVGSmoothPath({ path: pathLeft, windowWidth, windowHeight }).SVGStringPath);
        }
        return paths;
    }

    getMainPath(deviation = 0):Path {
        const endX:number = this.end.x;
        const deviationPixels = deviation;
        let yTarget = 35;
        if (this.windowWidth < 534) yTarget = 10;
        else if (this.windowWidth < 732) yTarget = 20;
        return new Path({
            points: [
                new Point({ x: this.start.x, y: -1 }),
                new Point({ x: this.start.x, y: 0 }),
                new Point({ x: yTarget + deviationPixels, y: 49 }),
                new Point({ x: yTarget + deviationPixels, y: 50 }),
                new Point({ x: yTarget + deviationPixels, y: 51 }),
                new Point({ x: endX, y: this.end.y - 1 }),
                new Point({ x: endX, y: this.end.y }),
            ],
        });
    }
}
