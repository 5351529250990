
















































































import { Component } from 'vue-property-decorator';
import formatDistance from 'date-fns/formatDistance';
import GraphicMixin from '@/mixins/GraphicMixin';
import AnimationPath from '@/components/Atoms/AnimationPath.vue';
import SVGSmoothPath from '@/classes/SVGSmoothPath';
import Point from '@/classes/Point';
import Path from '@/classes/Path';
import RainbowBackground from '@/components/Molecules/RainbowBackground.vue';

@Component({
    components: {
        AnimationPath,
        RainbowBackground,
    },
})
export default class ProfessionGraphic extends GraphicMixin {
    titleElementWidth: number;
    mainText: string;

    constructor() {
        super();
        this.graphicLayout = {
            text: {
                desktop: new Point({ x: 22, y: 50 }),
                mobile: new Point({ x: 4, y: 40 }),
            },
        };
        this.timeline = [
            { key: 'timeline', start: 0, end: 1 },
            { key: 'textOpacity', start: 0.2, end: 0.4 },
        ];
        this.titleElementWidth = 600;
        const timeSinceFeedbackCompany = formatDistance(new Date(2017, 9, 11), Date.now());
        this.mainText = `And I started working at Feedback Company ${timeSinceFeedbackCompany} ago. Building the frontend for a large scale web app in Vue, basically on my own!`;
    }

    get relativeLineWidth():number {
        return 16 / this.windowWidth * 100;
    }
    get timelinePath():string {
        const endX:number = this.end.x;
        const path = new Path({
            points: [
                new Point({ x: this.start.x, y: -1 }),
                new Point({ x: this.start.x, y: 0 }),
                new Point({ x: 50, y: 100 }),
                new Point({ x: endX, y: this.end.y - 1 }),
                new Point({ x: endX, y: this.end.y }),
            ],
        });
        const { windowWidth, windowHeight } = this;
        return new SVGSmoothPath({ path, windowWidth, windowHeight }).SVGStringPath;
    }
    get timelinePath2():string {
        const endX:number = this.end.x;
        const path = new Path({
            points: [
                new Point({ x: 20, y: -1 }),
                new Point({ x: 20, y: 0 }),
                new Point({ x: 29, y: 30 }),
                new Point({ x: endX, y: this.end.y - 1 }),
                new Point({ x: endX, y: this.end.y }),
            ],
        });
        const { windowWidth, windowHeight } = this;
        return new SVGSmoothPath({ path, windowWidth, windowHeight }).SVGStringPath;
    }
    get timelinePath3():string {
        const endX:number = this.end.x;
        const path = new Path({
            points: [
                new Point({ x: 10, y: -1 }),
                new Point({ x: 10, y: 0 }),
                new Point({ x: 12, y: 30 }),
                new Point({ x: endX, y: this.end.y - 1 }),
                new Point({ x: endX, y: this.end.y }),
            ],
        });
        const { windowWidth, windowHeight } = this;
        return new SVGSmoothPath({ path, windowWidth, windowHeight }).SVGStringPath;
    }
    get timelinePath4():string {
        const endX:number = this.end.x;
        const path = new Path({
            points: [
                new Point({ x: 80, y: -1 }),
                new Point({ x: 80, y: 0 }),
                new Point({ x: 50, y: 85 }),
                new Point({ x: endX, y: this.end.y - 1 }),
                new Point({ x: endX, y: this.end.y }),
            ],
        });
        const { windowWidth, windowHeight } = this;
        return new SVGSmoothPath({ path, windowWidth, windowHeight }).SVGStringPath;
    }
    get timelinePath5():string {
        const endX:number = this.end.x;
        const path = new Path({
            points: [
                new Point({ x: 35, y: -1 }),
                new Point({ x: 35, y: 0 }),
                new Point({ x: 45, y: 40 }),
                new Point({ x: endX, y: this.end.y - 1 }),
                new Point({ x: endX, y: this.end.y }),
            ],
        });
        const { windowWidth, windowHeight } = this;
        return new SVGSmoothPath({ path, windowWidth, windowHeight }).SVGStringPath;
    }
}
