









































import { Component, Watch } from 'vue-property-decorator';
import {
    State,
    Mutation,
} from 'vuex-class';
import Slide from '@/classes/Slide';
import Line from '@/classes/Line';
import Point from '@/classes/Point';
import SlideMixin from '@/mixins/SlideMixin';

@Component
export default class IntroSlide extends SlideMixin {
    @State('audioPermission') audioPermission: boolean;
    @Mutation('setAudioPermission') readonly setAudioPermission: CallableFunction;
    animationState:string;

    constructor() {
        super();
        this.animationState = 'start';
    }
    get animationClass():string {
        return `header--${this.animationState}`;
    }
    @Watch('windowSizeSum')
    updateLine(): void {
        const { start, end } = this.getTimelinePosition();
        this.updateSlide({
            index: this.index,
            line: new Line({ start, end }),
        });
    }
    getTimelinePosition(): Line {
        const timelineElement = this.$refs.timeline as HTMLElement;
        const timelinePosition = timelineElement.getBoundingClientRect();
        const start = new Point({
            x: timelinePosition.left + 8,
            y: timelineElement.offsetTop,
        });
        const end = new Point({
            x: timelinePosition.left + 8,
            y: timelineElement.offsetTop + timelinePosition.height,
        });
        return new Line({
            start,
            end,
        });
    }
    setAudioPermissionHandler(audioPermission:boolean):void {
        this.$gtag.event(`audio_permission${audioPermission ? '-enabled' : '-disabled'}`, {
            event_category: 'Permission Change',
            event_label: audioPermission ? 'Enabled' : 'Disabled',
            value: 1,
        });
        this.setAudioPermission(audioPermission);
    }
    mounted():void {
        setTimeout(() => {
            this.animationState = 'timeline-slid-in';
        }, 750);
        setTimeout(() => {
            this.animationState = 'timeline-showing';
        }, 750 + 900);
        setTimeout(() => {
            this.animationState = 'timeline-animation-complete';
            this.updateLine();
        }, 750 + 900 + 900);

        const { start, end } = this.getTimelinePosition();

        this.registerSlide(
            new Slide({
                index: this.index,
                line: new Line({ start, end }),
            }),
        );
    }
}
