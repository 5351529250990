












import { Component, Prop, Watch } from 'vue-property-decorator';
import GraphicMixin from '@/mixins/GraphicMixin';
import Point from '@/classes/Point';
import MusicNotesCanvas from '@/components/Illustrations/MusicNotesCanvas.vue';
import AudioVisualizer from '@/helpers/AudioVisualizer';

@Component({
    components: {
        MusicNotesCanvas,
    },
})
export default class AudioAnimation extends GraphicMixin {
    @Prop({ required: true }) readonly audioVisualizer: AudioVisualizer
    @Prop({ required: true }) readonly audioDataArray: Uint8Array

    graphicLayout = {
        textCoords: new Point({ x: 65, y: 43 }),
        textCoordsB500: new Point({ x: 90, y: 30 }),
        textCoordsB400: new Point({ x: 95, y: 30 }),
    }
    timeline = [
        { key: 'timeline', start: 0, end: 1 },
    ]
    rainbowColors:Array<string>;
    colorIndex:number;
    testFreq:number;

    constructor() {
        super();
        this.rainbowColors = ['red', 'yellow', 'green', 'blue'];
        this.colorIndex = 0;
        this.testFreq = 160;
    }

    private get kickIsActive():boolean {
        return (this.audioDataArray[2] > 250);
    }
    private get hiHatIsActive():boolean {
        return (
            this.audioDataArray[24] > this.testFreq
            && this.audioDataArray[39] > 150
        );
    }
    private get color():string {
        return this.rainbowColors[this.colorIndex];
    }
    private get isPlaying():boolean {
        return this.audioVisualizer.isPlaying;
    }

    @Watch('kickIsActive')
    private onKick():void {
        if (!this.kickIsActive) return;
        this.colorIndex += 1;
        if (this.colorIndex > this.rainbowColors.length - 1) {
            this.colorIndex = 0;
        }
    }
}
