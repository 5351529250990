import { render, staticRenderFns } from "./ComputerSlide.vue?vue&type=template&id=c10b6524&scoped=true&"
import script from "./ComputerSlide.vue?vue&type=script&lang=ts&"
export * from "./ComputerSlide.vue?vue&type=script&lang=ts&"
import style0 from "./ComputerSlide.vue?vue&type=style&index=0&id=c10b6524&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c10b6524",
  null
  
)

export default component.exports