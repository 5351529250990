
































import { Component } from 'vue-property-decorator';
import GraphicMixin from '@/mixins/GraphicMixin';
import AnimationPath from '@/components/Atoms/AnimationPath.vue';
import SVGSmoothPath from '@/classes/SVGSmoothPath';
import Point from '@/classes/Point';
import Path from '@/classes/Path';
import GraphicLocation from '@/classes/GraphicLocation';

@Component({
    components: {
        AnimationPath,
    },
})
export default class DDIntro extends GraphicMixin {
    graphicLayout = {
        textCoords: new Point({ x: 65, y: 43 }),
        textCoordsB500: new Point({ x: 90, y: 30 }),
        textCoordsB400: new Point({ x: 95, y: 30 }),
    }
    timeline = [
        { key: 'timeline', start: 0, end: 1 },
    ]

    get relativeLineWidth():number {
        return 16 / this.windowWidth * 100;
    }
    get textCoords():GraphicLocation {
        if (this.windowWidth < 400) return this.coords.textCoordsB400;
        if (this.windowWidth < 500) return this.coords.textCoordsB500;
        return this.coords.textCoords;
    }

    getTimelinePath(lineOffsetCount:number):string {
        const relativeLineWidth = 8 / this.windowWidth * 100;

        const endX:number = this.end.x + (lineOffsetCount * relativeLineWidth);
        const path = new Path({
            points: [
                this.start,
                new Point({ x: this.start.x, y: this.start.y + 0 }),
                new Point({ x: this.start.x, y: this.start.y + 1 }),
                new Point({ x: 60, y: 62 }),
                new Point({ x: 60, y: 62 }),
                new Point({ x: 58, y: 62 }),
                new Point({ x: endX, y: this.end.y - 1 }),
                new Point({ x: endX, y: this.end.y }),
            ],
        });
        const { windowWidth, windowHeight } = this;
        return new SVGSmoothPath({ path, windowWidth, windowHeight }).SVGStringPath;
    }
}
