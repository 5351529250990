






































































import { Component } from 'vue-property-decorator';
import GraphicMixin from '@/mixins/GraphicMixin';
import AnimationPath from '@/components/Atoms/AnimationPath.vue';
import SVGSmoothPath from '@/classes/SVGSmoothPath';
import Point from '@/classes/Point';
import Path from '@/classes/Path';

interface CircleSizeInterface {
    radius: number;
    diameter: number;
}

@Component({
    components: {
        AnimationPath,
    },
})
export default class HereNowGraphic extends GraphicMixin {
    titleElementWidth: number;

    constructor() {
        super();
        this.graphicLayout = {
            endOfLineCircle: new Point({ x: 50, y: this.end.y - 4 }),
            text: new Point({ x: 0, y: this.end.y - 4 }),
        };
        this.timeline = [
            { key: 'introTimeline', start: 0, end: 0.3 },
            { key: 'outroTimeline', start: 0.3, end: 1 },
        ];
        this.titleElementWidth = 600;
    }

    get outroPathsCount():number {
        return this.windowWidth < 1000 ? 4 : 8;
    }
    get introTimelinePath():string {
        const endX:number = this.end.x;
        const path = new Path({
            points: [
                new Point({ x: this.start.x, y: -1 }),
                new Point({ x: this.start.x, y: 0 }),
                new Point({ x: 50, y: 20 }),
                new Point({ x: endX, y: this.end.y - 1 }),
                new Point({ x: endX, y: this.end.y }),
            ],
        });
        const { windowWidth, windowHeight } = this;
        return new SVGSmoothPath({ path, windowWidth, windowHeight }).SVGStringPath;
    }
    get circleSize():CircleSizeInterface {
        const radius = this.windowWidth < 1000 ? 100 : 144;
        const diameter = radius * 2;

        return {
            radius,
            diameter,
        };
    }
    get outroPaths():Array<string> {
        const outroPathsCount = this.outroPathsCount + 1;
        const distance = 100;
        const spacing = distance / outroPathsCount;
        // const margin = spacing / 2;
        const outoPaths:Array<string> = [];

        for (let index = 1; index < outroPathsCount; index += 1) {
            // const element = array[index];
            const endX:number = spacing * index;
            const path = new Path({
                points: [
                    new Point({ x: this.start.x, y: this.end.y + 0 }),
                    new Point({ x: this.start.x, y: this.end.y + 1 }),
                    new Point({ x: 50, y: this.end.y + 4 }),
                    new Point({ x: endX, y: 100 - 1 }),
                    new Point({ x: endX, y: 100 }),
                ],
            });
            const { windowWidth, windowHeight } = this;
            outoPaths.push(new SVGSmoothPath({ path, windowWidth, windowHeight }).SVGStringPath);
        }

        return outoPaths;
    }
    get outroTimelinePath():string {
        const endX:number = this.end.x;
        const path = new Path({
            points: [
                new Point({ x: this.start.x, y: this.end.y }),
                new Point({ x: this.start.x, y: this.end.y }),
                new Point({ x: 50, y: 20 }),
                new Point({ x: endX, y: 100 - 1 }),
                new Point({ x: endX, y: 100 }),
            ],
        });
        const { windowWidth, windowHeight } = this;
        return new SVGSmoothPath({ path, windowWidth, windowHeight }).SVGStringPath;
    }
}
