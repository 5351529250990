














































import {
    Component, Watch,
} from 'vue-property-decorator';
import AnimationPath from '@/components/Atoms/AnimationPath.vue';
import Point from '@/classes/Point';
import Path from '@/classes/Path';
import SVGSmoothPath from '@/classes/SVGSmoothPath';
import GraphicMixin from '@/mixins/GraphicMixin';
import easingFunctions from '@/helpers/easingFunctions';
import ConfettiGenerator from 'canvas-confetti';
import minMax from '@/helpers/minMax';

@Component({
    components: {
        AnimationPath,
    },
})
export default class SchoolDoodle extends GraphicMixin {
    constructor() {
        super();
        this.graphicLayout = {
            computer: new Point({ x: 80, y: 28 }),
            text: {
                mobile: new Point({ x: 10, y: 33 }),
                desktop: new Point({ x: 30, y: 28 }),
            },
        };
        this.timeline = [
            { key: 'timeline', start: 0, end: 0.5 },
            { key: 'rainbow', start: 0.55, end: 1 },
            { key: 'textTransform', start: 0.5, end: 0.8 },
        ];
    }

    get textWidth():number {
        const padding = (this.windowWidth / 100) * 20;
        const width = this.windowWidth - padding;
        return minMax({
            number: width,
            min: 0,
            max: 600,
        });
    }
    get smallModeEngaged():boolean {
        return this.windowWidth < 1000;
    }
    get timelineSVGPath():string {
        const computerCoords = this.graphicLayout.computer as Point;
        const path = new Path({
            points: [
                new Point({ x: this.start.x, y: -1 }),
                new Point({ x: this.start.x, y: 0 }),
                new Point({ x: 80, y: 25 }),
                new Point({ x: computerCoords.x, y: computerCoords.y }),
            ],
        });
        const { windowWidth, windowHeight } = this;
        return new SVGSmoothPath({ path, windowWidth, windowHeight }).SVGStringPath;
    }
    get screenOn():boolean {
        return (this.animationPercentage > 0.5);
    }
    get confettiActivated():boolean {
        return (this.animationPercentage > 0.58);
    }
    get textStyle():Record<string, string|number> {
        const percentage = easingFunctions.easeOutQuad(this.as.textTransform);
        const translateTopBase = 65;
        const translateTop = translateTopBase + (120 * percentage);
        const translateLeft = 0;

        return {
            opacity: percentage,
            transform: `translate(${translateLeft}px, ${translateTop}px)`,
        };
    }
    get textAnchor():string {
        return this.smallModeEngaged ? 'middle' : 'end';
    }

    @Watch('windowSizeSum')
    private getRainbowPath(index: number):string {
        const relativeLineHeight = 16 / this.windowHeight * 100;
        const relativeLineWidth = 16 / this.windowWidth * 100;
        const computerCoords = this.graphicLayout.computer as Point;
        const start = computerCoords.y + 14;
        const endPointX = this.end.x + (index * relativeLineWidth);
        const endPoint = new Point({ x: endPointX, y: this.end.y - 1 });
        const path = new Path({
            points: [
                new Point({ x: computerCoords.x - 8, y: start }),
                new Point({ x: 50, y: start - 22 + (index * relativeLineHeight) }),
                new Point({ x: 50, y: start - 22 + (index * relativeLineHeight) }),
                new Point({ x: this.end.x - 10 - (index * relativeLineWidth), y: start }),
                new Point({ x: this.end.x - 10 - (index * relativeLineWidth), y: start + 1 }),
                endPoint,
                new Point({ x: endPoint.x, y: endPoint.y + 1 }),
            ],
        });
        const { windowWidth, windowHeight } = this;
        return new SVGSmoothPath({ path, windowWidth, windowHeight }).SVGStringPath;
    }

    @Watch('confettiActivated')
    private fireConfettiCanon(val: boolean):void {
        if (!val) return;
        const computerCoords = this.graphicLayout.computer as Point;
        const computerElement = this.$refs.computer as SVGElement;
        const confettiTop = (
            (computerElement.getBoundingClientRect().height / 2)
            / this.windowHeight
        );
        this.shootConfetti({
            spread: 20,
            angle: 150,
            // Bonus points if you know where these colours are from ;)
            colors: ['#5EBD3E', '#FFB900', '#F78200', '#E23838', '#973999', '#009CDF'],
            startVelocity: this.smallModeEngaged ? 30 : 60,
            particleCount: this.smallModeEngaged ? 100 : 200,
            origin: {
                x: computerCoords.x / 100,
                y: ((computerCoords.y) / 100) + confettiTop,
            },
        });
    }

    // Gets replaced in mounted, library has a poor API.
    // eslint-disable-next-line
    private shootConfetti({}) {}

    mounted():void {
        this.shootConfetti = ConfettiGenerator.create(
            document.querySelector('.confetti_canvas') as HTMLCanvasElement,
            { resize: true },
        );
        this.fireConfettiCanon(true);
    }
}
