


























import { Component } from 'vue-property-decorator';
import GraphicMixin from '@/mixins/GraphicMixin';
import AnimationPath from '@/components/Atoms/AnimationPath.vue';
import SVGSmoothPath from '@/classes/SVGSmoothPath';
import Point from '@/classes/Point';
import Path from '@/classes/Path';

@Component({
    components: {
        AnimationPath,
    },
})
export default class TheFutureGraphic extends GraphicMixin {
    titleElementWidth: number;

    constructor() {
        super();
        this.graphicLayout = {
            text: {
                desktop: new Point({ x: 24, y: 32 }),
                mobile: new Point({ x: 4, y: 40 }),
            },
        };
        this.timeline = [
            { key: 'timeline', start: 0, end: 0.6 },
            { key: 'circleIntro', start: 0.6, end: 0.7 },
        ];
        this.titleElementWidth = 600;
    }

    get timelineCount():number {
        return this.windowWidth < 1000 ? 4 : 8;
    }
    get timelinePaths():Array<SVGSmoothPath> {
        const timelineCount = this.timelineCount + 1;
        const spacing = 100 / timelineCount;
        const outroPaths:Array<SVGSmoothPath> = [];
        const endPointYs = [15, 25, 22, 10, 28, 25, 15, 30];

        for (let index = 1; index < timelineCount; index += 1) {
            // const element = array[index];
            const x:number = spacing * index;
            const path = new Path({
                points: [
                    new Point({ x, y: 0 }),
                    new Point({ x, y: 0 }),
                    new Point({ x, y: endPointYs[index - 1] - 1 }),
                    new Point({ x, y: endPointYs[index - 1] }),
                ],
            });
            const { windowWidth, windowHeight } = this;
            outroPaths.push(new SVGSmoothPath({ path, windowWidth, windowHeight }));
        }
        return outroPaths;
    }
    get timelinePathAsStrings():Array<string> {
        return this.timelinePaths.map(timelinePath => timelinePath.SVGStringPath);
    }
    get endPoints():Array<Point> {
        return this.timelinePaths.map((timelinePath:SVGSmoothPath) => {
            const { points } = timelinePath.path;
            return points[points.length - 1];
        });
    }
}
